@import 'assets/styles/mixins.scss';

.topbar {
  background: transparent;
  min-height: rem(64);
  border-bottom: 1px solid $border;
  padding: rem(15) rem(20);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
