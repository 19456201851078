.ant-calendar-picker {
  margin-right: 20px !important;
}
.style_air__menuLeft__logo__3ZKhW img {
  float: left;
  margin-top: 0px;
}
.style_air__menuLeft__logo__name__w3dDc {
  font-weight: 900;
  color: #fff;
  font-size: 21px;
  margin-left: 2.66rem;
  padding-top: 5px;
}

.map-d {
  margin-bottom: 0px !important;
}

.prgs-card {
  text-align: center;
}
.prgs-card p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: -4px;
}

.ant-table-layout-fixed table {
  width: auto !important;
}

.ant-modal-body .ant-form-item {
  margin: 0px !important;
}

.business-details {
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 4px;
  padding: 20px 20px 10px 20px;
  margin-bottom: 20px;
  text-align: left;
}

.coupon-details .ant-input-lg {
  text-align: center;
}
.reg-form .ant-input-lg {
  height: 30px;
  padding: 5px 8px;
  font-size: 16px;
}
h2.reg {
  margin: 15px 0 5px;
  color: #32325d;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.business-details .ant-form-item {
  margin-bottom: 5px;
}

.reg-total h4 {
  font-weight: 600;
}
.payment-details {
  margin-bottom: 25px;
}

.pricing {
  max-width: 1200px;
  margin: auto;
}

.pricing .ant-col {
  margin-right: -1px;
  border-right: 1px solid #e4e9f0;
  border-left: 1px solid #e4e9f0;
  border-radius: 0;
}

.ant-card-body {
  padding: 0 !important;
}

.font-size-40 {
  font-size: 1.66rem;
}

.pricing .flex-grow-1 {
  flex-grow: 1 !important;
  min-width: 215px;
  max-width: 215px;
}

tr.active {
  background-color: lightblue;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .ant-slider-mark {
    font-size: 12px !important;
  }
  .pricing .flex-grow-1 {
    flex-grow: 1 !important;
    min-width: 300px !important;
    max-width: 300px !important;
  }
}

.ant-input-number {
  width: 100% !important;
}
.buy .ant-form {
  text-align: left!important;
}
